<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row>
        <form id="bemFilter" class="m-t-sm">
          <div class="row erp-row def-m-r">

            <e-col style="min-width: 295px">
              <comitente-select @loaded="$emit('loadedComitente')" multiple :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="table.filters.comitente" />
            </e-col>

            <e-col style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Código do Bem"
              >
                <erp-input shortkey="F2" v-model="table.filters.codigo" />
              </erp-s-field>
            </e-col>

            <e-col style="max-width: 130px; min-width: 130px">
              <tipo-bem-select
                  ref="tipoPai"
                  placeholder="Qualquer"
                  label="Tipo de bem:"
                  field-view="tl"
                  list-all=""
                  v-model="table.filters.tipoBem" />
            </e-col>

            <e-col style="max-width: 180px; min-width: 130px">
              <marca-select placeholder="Selecione" label-width="110px"
                            label="Montadora/Marca:"
                            disable-create
                            v-model="table.filters.marca" />
            </e-col>

            <e-col style="max-width: 180px; min-width: 130px">
              <modelo-select
                  :disable="!table.filters.marca"
                  :marca="table.filters.marca"
                  placeholder="Selecione"
                  label="Modelo:"
                  disable-create
                  v-model="table.filters.modelo" />
            </e-col>

            <e-col style="max-width: 75px; min-width: 75px">
              <erp-s-field
                  view="tl"
                  label="Ano Modelo"
              >
                <erp-input v-model="table.filters.ano" />
              </erp-s-field>
            </e-col>

            <e-col style="max-width: 175px; min-width: 75px">
              <erp-s-field
                  view="tl"
                  label="Placa"
              >
                <erp-input shortkey="F3" v-model="table.filters.placa" />
              </erp-s-field>
            </e-col>

            <e-col style="min-width: 120px; max-width: 200px">
              <erp-s-field
                  view="tl"
                  label="Chassi"
              >
                <erp-input v-model="table.filters.chassi" />
              </erp-s-field>
            </e-col>

            <!-- NL -->

            <e-col style="max-width: 130px; min-width: 130px">
              <cor-select
                  placeholder="Qualquer"
                  label="Cor predominante:"
                  v-model="table.filters.cor" />
            </e-col>

            <e-col style="min-width: 100px">
              <erp-s-field
                  view="tl"
                  label="Código Fipe"
              >
                <erp-input v-model="table.filters.codigoFipe" />
              </erp-s-field>
            </e-col>

            <e-col style="max-width: 130px; min-width: 130px">
              <erp-s-field
                  view="tl"
                  label="Pátio"
              >
                <erp-select
                    placeholder="Qualquer"
                    :options="filtersData.patio.serverData"
                    v-model="table.filters.patio" />
              </erp-s-field>
            </e-col>

            <e-col style="min-width: 174px">
              <erp-s-field
                  view="tl"
                  label="Busca inteligente"
              >
                <erp-input v-model="table.filter" />
              </erp-s-field>
            </e-col>

            <e-col style="min-width: 130px">
              <erp-s-field
                  view="tl"
                  label="Processo/Contrato/Partes"
              >
                <erp-input shortkey="F4" v-model="table.filters.processo" />
              </erp-s-field>
            </e-col>

            <e-col style="min-width: 300px; max-width: 400px">
              <status-select multiple v-model="table.filters.situacao" />
            </e-col>

            <e-col style="min-width: 130px">
              <erp-s-field
                  view="tl"
                  label="Bloqueado para leilão"
              >
                <erp-checkbox v-model="table.filters.bloqueadoLeilao" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Prov. Alagamento"
              >
                <erp-checkbox v-model="table.filters.alagamento" />
              </erp-s-field>
            </e-col>

            <e-col style="min-width: 126px;">
              <date-input label="Data de Entrada (De)" v-model="table.filters.dataEntrada1" />
            </e-col>

            <e-col style="min-width: 126px;">
              <date-input label="Data de Entrada (Até)" v-model="table.filters.dataEntrada2" />
            </e-col>

            <e-col style="min-width: 126px;">
              <date-input label="Data de Saída (De)" v-model="table.filters.dataSaida1" />
            </e-col>

            <e-col style="min-width: 126px;">
              <date-input label="Data de Saída (Até)" v-model="table.filters.dataSaida2" />
            </e-col>
          </div>
        </form>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
<!--        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />-->
        <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" icon="empty-set" icon-type="fa" icon-style="light" v-shortkey="['ctrl', 'shift', 'r']"
               @shortkey.native="clearFilters" flat no-caps />
        <u-btn @click="exportar('excel')" label="Gerar Excel" icon="file-spreadsheet" icon-type="fa" icon-style="light" flat no-caps :loading="table.loading" />
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpBox,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ComitenteSelect from "@/components/comitente/helpers/input/ComitenteSelect"
import TipoBemSelect from "@/components/bem/components/include/TipoSelect"
import MarcaSelect from "@/components/bem/components/include/MarcaSelect"
import ModeloSelect from "@/components/bem/components/include/ModeloSelect"
import CorSelect from "@/components/bem/components/include/CorSelect"
import StatusSelect from "@/components/bem/components/include/StatusSelect"
import DateInput from "@/reuse/input/Date"
import ECol from "@/components/layout/components/Col";
import filtersData from "@/components/bem/helpers/filtersData"
import {datePtToEn} from "@/utils/date";
import {date} from "uloc-vue";
import {list} from "@/domain/bem/services";
import {donwloadFile} from "@/utils/downloadFile";

let filters = filtersData
filters.situacao = []

export default {
  name: 'RelatorioEstoqueWindow',
  data () {
    return {
      filtersData: {
        loadedAll: false,
        loadedAllError: false,
        comitentes: {
          loading: true,
          serverData: []
        },
        tipoBem: {
          loading: true,
          serverData: []
        },
        marca: {
          loading: true,
          serverData: []
        },
        modelo: {
          loading: true,
          serverData: []
        },
        cor: {
          loading: true,
          serverData: []
        },
        patio: {
          loading: true,
          serverData: []
        }
      },
      table: {
        sortBy: 'dataEntrada',
        sortByDesc: false,
        busca: '',
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  mounted () {
  },
  computed: {},
  methods: {
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    request ({pagination, filter}, exportar = null) {

      let data1, data2
      let extraFilters = []
      /*
      if (this.filtros.tipoFiltroData === '-1' && (!this.filtros.data1 || !this.filtros.data2)) {
        alert('Informe um intervalo de datas para processar o filtro')
        return
      }*/
      if (this.table.filters.dataEntrada1) {
        if (this.table.filters.dataEntrada1.length < 10 || this.table.filters.dataEntrada2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.dataEntrada1)
        data2 = datePtToEn(this.table.filters.dataEntrada2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&dataEntrada1=${data1}&dataEntrada2=${data2}`)
      }

      if (this.table.filters.dataSaida1) {
        if (this.table.filters.dataSaida1.length < 10 || this.table.filters.dataSaida2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.dataSaida1)
        data2 = datePtToEn(this.table.filters.dataSaida2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&dataSaida1=${data1}&dataSaida2=${data2}`)
      }

      if (Array.isArray(this.table.filters.comitente) && this.table.filters.comitente.length > 0) {
        extraFilters.push(`&comitentes=${this.table.filters.comitente.join(',')}`)
      }
      this.table.filters.codigo && extraFilters.push(`&id=${this.table.filters.codigo}`)
      this.table.filters.tipoBem && extraFilters.push(`&tipo=${this.table.filters.tipoBem}`)
      this.table.filters.marca && extraFilters.push(`&marca=${this.table.filters.marca}`)
      this.table.filters.modelo && extraFilters.push(`&modelo=${this.table.filters.modelo}`)
      this.table.filters.ano && extraFilters.push(`&ano=${this.table.filters.ano}`)
      this.table.filters.placa && extraFilters.push(`&placa=${this.table.filters.placa}`)
      this.table.filters.chassi && extraFilters.push(`&chassi=${this.table.filters.chassi}`)
      this.table.filters.cor && extraFilters.push(`&cor=${this.table.filters.cor}`)
      this.table.filters.situacao && extraFilters.push(`&status=${this.table.filters.situacao}`)
      this.table.filters.codigoFipe && extraFilters.push(`&codigoFipe=${this.table.filters.codigoFipe}`)
      this.table.filters.bloqueadoLeilao && extraFilters.push(`&bloqueadoLeilao=${this.table.filters.bloqueadoLeilao}`)
      this.table.filters.alagamento && extraFilters.push(`&alagamento=1`)

      if (exportar) {
        extraFilters.push(`&export=${exportar}`)
      }

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${this.table.sortBy}&descending=${this.table.sortByDesc}&search=${filter}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, exportar)
          .then((response) => {
            console.log(response)
            if (exportar) {
              donwloadFile(response)
              this.table.loading = false
              return
            }
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = response.data.total
            this.table.serverData = response.data.result

            this.table.loading = false
          })
          .catch(error => {
            this.table.loading = false
          })
    },
    exportar (tipo = 'excel') {
      this.request({
        pagination: {},
        filter: this.table.filter
      }, tipo)
    }
  },
  components: {
    ECol,
    // ECol,
    DateInput,
    StatusSelect,
    CorSelect,
    ModeloSelect,
    MarcaSelect,
    TipoBemSelect,
    ComitenteSelect,
    ERow,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel
    // UEditor,
    // URadio
    ErpSelect,
    ErpCheckbox
  }
}
</script>
